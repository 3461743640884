
///include /assets/js/app/p/service-limit-support.js
///include /assets/js/plugins/network-property-set.js

"use strict"

/**
 * @typedef allowanceServiceIn
 * @property {string} id
 * @property {jsonLimitValue} count
 * @property {?string} expiryDate
 * @property {string} name
 * @property {packageIn[]} packages
 * @property {string} productSpec
 * @property {string} serviceId
 * @property {"wordpress_allowance" | "package_allowance"} serviceType
 */

class AllowanceService {
    /**
     *
     * @param {allowanceServiceIn} d
     */
    constructor(d) {
        this.id         = d.id
        this.name       = d.name
        this.count      = ServiceLimitSupport.realLimitValue(d.count)
        this.expiryDate = d.expiryDate

        if( !d.location && d.packages && d.packages.length > 0 ){
            this.dcLocation = "dc_location-uk";
        } else {
            this.dcLocation = d.location;
        }

        /**
         * @type {PackageService[]}
         */
        this.packages = d.packages.map(
            p => new PackageService(p, this)
        ).sort(
            (a, b) => a.name.localeCompare(b.name)
        )
        this.productSpec     = d.productSpec;
        this.timelineService = d.timelineService;
        this.store           = (typeof Store == "undefined" ? null : (new Store()));
    }
    get upgradeProduct() {
        if( (this.timelineService == 'free' || this.timelineService == 'normal') && this.store?.allProducts?.length > 0 ){
            var code = this.productSpec.split("-")[1];
            return this.store.allProducts.find( p => p.productCode == `timeline_storage_upgrade-${code}` );
        } else {
            return false;
        }
    }
    get label() {
        return this.name
    }
    /** @type {{start: string, success: string, failure: string}} */
    get messages() {
        throw new Error("Not implemented")
    }
    /**
     * @type {string}
     */
    get serviceType() {
        throw new Error("Not implemented")
    }
    /**
     *
     * @param {string} name
     * @return {JQuery.jqXHR}
     */
    addSite(name) {
        throw new Error("Not implemented")
    }
    /**
     * Delete a package
     * @param {PackageService} pkg
     */
    delete(pkg) {
        return $.ajax({
            method: "post",
            url: `/x/${this.serviceType}/${this.id}/deleteWeb`,
            data: {
                "delete-id": [pkg.id],
            },
        })
    }
    /**
     * Set store object
     * @param {Store} store
     */
    setStore(store) {
        this.store = store;
    }
}

class ManagedVpsAllowanceService extends AllowanceService {
    get messages() {
        return {
            start: "Creating your package... please wait",
            success: "Successfully created your package.",
            failure: "There was a problem creating your package. Please contact support.",
        }
    }
    get serviceType() {
        return "managed_vps"
    }
    /**
     *
     * @param {string} name
     * @return {JQuery.jqXHR}
     */
    addSite(name) {
        return $.ajax({
            method: "post",
            url: `/a/managed_vps/${this.id}/addWeb`,
            data: {
                domain_name: name,
            },
        })
    }
}

class AutoscaleAllowanceService extends AllowanceService {
    constructor(a) {
        super(a)
        this.optimisationProfile = "Default";
        this.selectedLocation    = "uk";
    }

    get isAutoscaleService() {
        return true;
    }

    get locationOptions() {
        return {
            "1": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/dc-locations/uk.svg",
                    "darkMode": "/assets/images/dc-locations/uk.svg",
                    "activeMode": "/assets/images/dc-locations/uk.svg"
                },
                "id": "uk",
                "title": "London, UK",
            },
            "2": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/dc-locations/usa.svg",
                    "darkMode": "/assets/images/dc-locations/usa.svg",
                    "activeMode": "/assets/images/dc-locations/usa.svg"
                },
                "id": "usa",
                "title": "Dallas, US",
            }
        };
    }

    get messages() {
        return {
            start: "Creating your package... please wait",
            success: "That domain is already on a package",
            failure: "There was a problem creating your package. Please contact support.",
        }
    }

    get optimisationProfiles() {
        return {
            "1": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/server-optimisations/php.svg",
                    "darkMode": "/assets/images/server-optimisations/php.svg",
                    "activeMode": "/assets/images/server-optimisations/php-white.svg"
                },
                "id": "Default",
                "title": "PHP",
                "version": "",
                "width": "175px"
            },
            "2": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/server-optimisations/wordpress.svg",
                    "darkMode": "/assets/images/server-optimisations/wordpress-dm.svg",
                    "activeMode": "/assets/images/server-optimisations/wordpress-white.svg"
                },
                "id": "WordPress",
                "title": "WordPress",
                "version": "",
                "width": "200px"
            },
            "5": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/server-optimisations/laravel.svg",
                    "darkMode": "/assets/images/server-optimisations/laravel.svg",
                    "activeMode": "/assets/images/server-optimisations/laravel-white.svg"
                },
                "id": "Laravel",
                "title": "Laravel",
                "version": "",
                "width": "175px"
            },
            "7": {
                "available": true,
                "icon": {
                    "lightMode": "/assets/images/server-optimisations/joomla.svg",
                    "darkMode": "/assets/images/server-optimisations/joomla.svg",
                    "activeMode": "/assets/images/server-optimisations/joomla-white.svg"
                },
                "id": "Joomla",
                "title": "Joomla!",
                "version": "",
                "width": "175px"
            }
        };
    }

    get serviceType() {
        return "autoscale_allowance"
    }

    /**
     *
     * @param {string} name
     * @return {JQuery.jqXHR}
     */
    addSite(name) {
        let optimisationProfile  = this.optimisationProfile;
        this.optimisationProfile = "Default";
        return $.ajax({
            method: "post",
            url: `/x/autoscale_allowance/${this.id}/addWeb`,
            data: {
                domain_name: name,
                profile: optimisationProfile,
                dcLocation: this.selectedLocation
            },
        })
    }
}

class PackageAllowanceService extends AllowanceService {
    get messages() {
        return {
            start: "Creating your package... please wait",
            success: "That domain is already on a package",
            failure: "There was a problem creating your package. Please contact support.",
        }
    }
    get serviceType() {
        return "package_allowance"
    }
    /**
     *
     * @param {string} name
     * @return {JQuery.jqXHR}
     */
    addSite(name) {
        return $.ajax({
            method: "post",
            url: `/x/package_allowance/${this.id}/addWeb`,
            data: {
                domain_name: name,
            },
        })
    }
}

class WordpressAllowanceService extends AllowanceService {
    get label() {
        return `WordPress ${this.name} Hosting`
    }
    get messages() {
        return {
            start: "Creating your WordPress package... please wait",
            success: "Successfully created your WordPress package.",
            failure: "There was a problem creating your WordPress package. Please contact support.",
        }
    }
    get serviceType() {
        return "wordpress_allowance"
    }
    /**
     *
     * @param {string} name
     * @return {JQuery.jqXHR}
     */
    addSite(name) {
        return $.ajax({
            method: "post",
            url: `/x/wordpress_allowance/${this.id}/addWeb`,
            data: {
                domain_name: name,
            },
        })
    }
}

/**
 * @typedef packageIn
 * @property {string} id
 * @property {?string} externalId
 * @property {string[]} names
 * @property {string} name
 * @property {string} packageTypeName
 * @property {?string} packageTypePlatform
 * @property {string} created
 * @property {{label: string}[]} packageLabels
 * @property {{hasSsl: {[name: string]: boolean}}} web
 */

/**
 * @typedef packageDetail
 * @property {boolean} enabled
 * @property {string} oid
 * @property {string[]} stackUsers
 * @property {*} web
 */

class PackageService extends NetworkPropertySet {
    /**
     * @type {{[id: string]: Promise<{id: string, stackUsers: *[], enabled: boolean, oid: string, web: {platform: string}}>}}
     */
    static get batchDetailPromises() {
        if(!this._batchDetailPromises) {
            this._batchDetailPromises = {}
        }
        return this._batchDetailPromises
    }
    /**
     *
     * @param {string} id
     */
    static batchGetDetail(id) {
        if(!this.batchDetailPromises[id]) {
            this.batchDetailPromises[id] = new Promise(resolve => {
                if(this.batchDetailRefs) {
                    this.batchDetailRefs[id] = resolve
                } else {
                    const refs = this.batchDetailRefs = {
                        [id]: resolve
                    }
                    setTimeout(async () => {
                        this.batchDetailRefs = null
                        const ids = Object.keys(refs)
                        const CHUNK_SIZE = 64
                        for(let i = 0; i < ids.length; i += CHUNK_SIZE) {
                            const results = await $.ajax({
                                method: "get",
                                url: `/o/package`,
                                data: {
                                    purpose: "web",
                                    fields: [
                                        "id",
                                        "stackUsers",
                                        "enabled",
                                        "web.platform",
                                        "web.hasSsl",
                                        "web.hasPremiumSsl",
                                        "web.hasFreeSsl"
                                    ],
                                    selector: {
                                        id: ids.slice(i, i + CHUNK_SIZE),
                                    },
                                },
                            })
                            for(const r of results) {
                                if(refs[r.id]) {
                                    refs[r.id](r)
                                }
                            }
                        }
                    }, 30)
                }
            })
        }
        return this.batchDetailPromises[id]
    }
    /**
     * Reduce the amount of items in the batchGetDetailPromises object. The
     * object can get too large on accounts with many packages (thousands) and
     * can cause an OOM issue. Called statically when navigating through pages
     * to check the size of the cache and clear items out as necessary.
     *
     * Cache size set to 100 as per the default page size (so on a new load,
     * cache will build up to a maximum of 200 (100 in cache + 100 extra on page)).
     */
    static reduceBatchDetailPromisesCache() {
        if (
            Object.keys(this.batchDetailPromises).length &&
            Object.keys(this.batchDetailPromises).length > 100
        ) {
            const len = Object.keys(this.batchDetailPromises).length
            Object.keys(this.batchDetailPromises).forEach((k, i) => {
                if (i < (len - 100)) {
                    delete this.batchDetailPromises[k]
                }
            })
        }
    }
    /**
     *
     * @param {packageIn} d
     * @param {WordpressAllowanceService | PackageAllowanceService | null} [allowance]
     */
    constructor(d, allowance = null) {
        super()

        this.allowance = allowance
        this.beingManaged = d.beingManaged
        this.pseudoAllowance = d.pseudoAllowance
        this.created = d.created
        this.deactivatedDate = null;
        /** @type {?packageDetail} */
        this.detail = null
        /** @type {{[name: string]: ?boolean}} */
        this.id = d.id
        this.externalId = d.externalId
        this.hasWebsiteTurbo = d.hasWebsiteTurbo;
        this.isExpanded = false
        this._isFavourite = d.isFavourite ?? false
        this.dcLocation = d.location
        this.name = d.name
        this.names = d.names
        this.typeRef = d.typeRef
        this.platform = d.web && d.web.platform
        this.packageLabels = d.packageLabels
        this.packageTypeName = d.packageTypeName
        this.packageTypePlatform = d.packageTypePlatform
        this.selected = false
        this.modifying = false
        this.isDropdownExpanded = false
        this.websiteTurbo = d.websiteTurbo
    }

    get isFavourite() {
        return this._isFavourite;
    }

    set isFavourite(v) {
        this._isFavourite = v;
    }


    get createdDate() {
        if( this.created ){
            return tdUtils.createDate(this.created)
        }
    }

    get hasSsl() {
        const out = []
        if(this.detail && this.detail.web && this.detail.web.hasSsl) {
            const pattern = new RegExp(
                `[^.]+[.]${this.name?.replace(/[.]/g, "[.]")}$`
            )

            for(const [name, value] of Object.entries(this.detail.web.hasSsl)) {
                if(name.match(pattern)) {
                    out[name] = null
                } else {
                    out[name] = value
                }
            }
        }

        return out
    }

    /** @type {boolean} */
    get enabled() {
        return this.detail ? this.detail.enabled : true
    }
    set enabled(s) {
        if(s) {
            this.modifying = true
            $.ajax({
                method: "post",
                url: `/x/package/${this.oid}/userStatus`,
                data: {
                    includeRepeated: +true,
                    subservices: {
                        default: +true
                    },
                },
            }).then(
                () => {
                    if(this.detail) {
                        this.detail.enabled = true
                        if(!window['bulkNotifications']) {
                            ShowNotification.success(
                                "Successfully enabled package, please allow 30 minutes for this to take effect."
                            )
                        }
                    }
                    this.modifying = false
                },
                () => {
                    ShowNotification.fail(`Could not enable ${this.name}`)
                    this.modifying = false
                }
            )
        } else {
            this.modifying = true
            $.ajax({
                method: "post",
                url: `/x/package/${this.oid}/userStatus`,
                data: {
                    includeRepeated: +true,
                    subservices: {
                        default: +false
                    },
                },
            }).then(
                () => {
                    if(this.detail) {
                        this.detail.enabled = false
                        if(!window['bulkNotifications']) {
                            ShowNotification.success(
                                "Successfully disabled package, please allow 30 minutes for this to take effect."
                            )
                        }
                    }
                    this.modifying = false
                },
                () => {
                    ShowNotification.fail(`Could not disable ${this.name}`)
                    this.modifying = false
                }
            )
        }
    }

    /**
     * @return {string}
     */
    get packageTypeNameExcerpt() {
        let length = 25
        if(this.packageTypeName) {
            if(this.packageTypeName.length > length) {
                return `${this.packageTypeName.substring(0, length)}...`
            } else {
                return this.packageTypeName
            }
        }
        // TODO: This should not be here...
        return "Free Hosting"
    }

    get inManagedVpsAllowance() {
        return this.allowance instanceof ManagedVpsAllowanceService || this.pseudoAllowance instanceof ManagedVpsAllowanceService;
    }

    get inAutoscaleAllowance() {
        return this.allowance instanceof AutoscaleAllowanceService
    }

    get inPackageAllowance() {
        return this.allowance instanceof PackageAllowanceService
    }

    get inWordpressAllowance() {
        return this.allowance instanceof WordpressAllowanceService
    }

    get isAutoscaleAllowance() {
        return this.allowance && this.allowance.productSpec.match(/autoscale/);
    }

    get networkPropertyHandlers() {
        return {
            deactivatedDate: ()  => $.ajax(`/a/package/${this.id}/deactivationDate`),
            detail: () => PackageService.batchGetDetail(this.id),
            phpVersion: () => $.ajax(`/a/package/${this.id}/web/phpVersion`).then(res => {
                let version = res.split('php')[1]
                return `${version.slice(0, 1)}.${version.slice(1)}`
            }),
            subdomains: () => $.ajax(`/a/package/${this.id}/web/subdomains`),
            usage: () => $.ajax(`/a/package/${this.id}/web/usage`)
        }
    }

    get labels() {
        return this.packageLabels
    }

    get oid() {
        return this.detail && this.detail.oid
    }

    get otherNames() {
        return this.names.filter(n => n != this.name)
    }

    /**
     * @type {?string[]} eg. ["stack-user:1234"]
     */
    get stackUsers() {
        return this.detail && this.detail.stackUsers
    }

    get web() {
        return this.detail && this.detail.web
    }
}

/**
 * @typedef instanceArrayData
 * @property {string|boolean} loaded
 * @property {any[]} items
 *
 * @typedef instanceObjectData
 * @property {string|boolean} loaded
 * @property {any} data
 *
 * @typedef instanceItemData
 * @property {string|boolean} loaded
 * @property {?string} content
 */

function checkArraysAreEqual(arrayA, arrayB) {
    if (!Array.isArray(arrayA) || !Array.isArray(arrayB)) {
        return false;
    } else if (arrayA === arrayB) {
        return true;
    } else if (arrayA.length !== arrayB.length) {
        return false;
    } else {
        for (let x = 0; x < arrayA.length; ++x) {
            if (arrayA[x] !== arrayB[x]) return false;
        }
        return true;
    }
}

class WordpressPackageService extends PackageService {
    constructor(d, allowance = null, missionControl) {
        super(d, allowance)

        const queueId = missionControl.queueId
        const jobData = missionControl.updatePackageJobs(d)

        // Track which multi row switches has been used
        this.allSwitches = {}

        this.committing = {
            themes: false,
            plugins: false
        }

        this.missionControl = missionControl
        this.queueId = queueId

        this.instanceData = {
            isMultisite: null,
            wizardRequired: null,
            stagingLink: {
                loaded: false,
                content: null
            },
            /**
             * @type {instanceItemData}
             */
            adminLink: {
                loaded: false,
                content: null
            }
        }

        this.wpData = {
            version: null,
            themes: null,
            plugins: null,
            users: null
        }

        this.userChanges = {}

        this.updateJobs(jobData)
        // this.getAllListData()
    }

    get networkPropertyHandlers() {
        return {
            detail: () => PackageService.batchGetDetail(this.id),
            phpVersion: () => $.ajax(`/a/package/${this.id}/web/phpVersion`).then(res => {
                let version = res.split('php')[1]
                return `${version.slice(0, 1)}.${version.slice(1)}`
            }),
            wpVersion: () => this.missionControl
                .getListData('core', this.externalId)
                .then(response => this.processResult(response, 'core')),
            wpUpdates: () => this.missionControl
                .getUpdateData(this.externalId)
                .then(response => this.processResult(response, 'coreUpdate')),
            wpThemes: () => this.missionControl
                .getListData('theme', this.externalId)
                .then(response => this.processResult(response, 'theme')),
            wpPlugins: () => this.missionControl
                .getListData('plugin', this.externalId)
                .then(response => this.processResult(response, 'plugin')),
            wpUsers: () => this.missionControl
                .getListData('user', this.externalId)
                .then(response => this.processResult(response, 'user'))
        }
    }

    processResult(response, type) {
        if (type === 'core') {
            return response.result.result
        }

        if (type === 'coreUpdate') {
            try {
                const updates = JSON.parse(response.result.result.replaceAll('\'', '"'))
                const major = updates.find(update => update.update_type === 'major')
                const minor = updates.find(update => update.update_type === 'minor')
                return {
                    major,
                    minor
                }
            } catch (e) {
                // Stick with null
                return null;
            }
        }

        return JSON.parse(response.result.result.replaceAll('\'', '"')).map(item => {
            if (type === 'plugin') {
                item.mustUse = (item.status == 'must-use');
                console.log(item)
            }

            item.actionChecks = {}

            if (type === 'user') {
                item.selected = false
                item.editingUserRole = false
                item.capabilities = false
                item.roles = item.roles.split(',')
                item.newRoles = [...item.roles]

                // In the case of no roles on a user
                if (item.roles[0] === "") {
                    item.roles = []
                }
            }

            return item
        })
    }

    /**
     * Gets all the relevant info for UI display.
     * This can be re-run to fetch all fresh data.
     */
    async getAllListData() {
        const data = await Promise.all([
            this.missionControl.getListData('core', this.externalId),
            this.missionControl.getListData('theme', this.externalId),
            this.missionControl.getListData('plugin', this.externalId),
            this.missionControl.getListData('user', this.externalId)
        ])

        // WP Core Version
        if (data[0]) {
            console.log(data[0].result)
            this.wpData.version = this.processResult(data[0])
        }

        // Themes
        if (data[1]) {
            this.wpData.themes = this.processResult(data[1], true)
        }

        // Plugins
        if (data[2]) {
            this.wpData.plugins = this.processResult(data[2], true)
        }

        // Users
        if (data[3]) {
            this.wpData.users = this.processResult(data[3], true)
        }
    }

    get activatedPlugins() {
        if (!this.wpPlugins || !this.wpPlugins.length) {
            return null
        }

        return this.wpPlugins.filter(plugin => plugin.status === 'inactive')
    }

    get deactivatedPlugins() {
        if (!this.wpPlugins || !this.wpPlugins.length) {
            return null
        }

        return this.wpPlugins.filter(plugin => plugin.status === 'active')
    }

    get activatedThemes() {
        if (!this.wpThemes.length) {
            return null
        }

        return this.wpThemes.filter(theme => theme.status === 'inactive')
    }

    get deactivatedThemes() {
        if (!this.wpThemes || !this.wpThemes.length) {
            return null
        }

        return this.wpThemes.filter(theme => theme.status === 'active')
    }

    get infectedFiles() {
        if (
            !this.instanceData.malwareReport.data ||
            !this.instanceData.malwareReport.data.infectedFiles ||
            !Object.keys(this.instanceData.malwareReport.data.infectedFiles).length
        ) {
            return null
        }

        return Object.entries(this.instanceData.malwareReport.data.infectedFiles)
    }

    get warnedFiles() {
        if (
            !this.instanceData.malwareReport.data ||
            !this.instanceData.malwareReport.data.warnedFiles ||
            !Object.keys(this.instanceData.malwareReport.data.warnedFiles).length
        ) {
            return null
        }

        return Object.entries(this.instanceData.malwareReport.data.warnedFiles)
    }

    get updateableThemes() {
        if (!this.wpThemes || !this.wpThemes.length) {
            return null
        }

        return this.wpThemes.filter(theme => theme.update !== 'none')
    }

    get updateablePlugins() {
        if (!this.wpPlugins || !this.wpPlugins.length) {
            return null
        }

        return this.wpPlugins.filter(plugin => plugin.update == 'available')
    }

    get totalUpdates() {
        let total = 0

        if (this.updateablePlugins && this.updateablePlugins.length) {
            total += this.updateablePlugins.length
        }

        if (this.updateableThemes && this.updateableThemes.length) {
            total += this.updateableThemes.length
        }

        return total
    }

    get noStackCachePlugins() {
        if (!this.wpPlugins || !this.wpPlugins.length) {
            return []
        }

        return this.wpPlugins.filter(plugin => plugin.name !== 'wp-stack-cache')
    }

    get selectedUsers() {
        if (!this.wpUsers || !this.wpUsers.length) {
            return null
        }

        return this.wpUsers.filter(user => user.selected === true)
    }

    get users() {
        if (!this.wpUsers || !this.wpUsers.length) {
            return null
        }

        return this.wpUsers
    }

    get wpAdmins() {
        if (!this.wpUsers || !this.wpUsers.length) {
            return null
        }

        return this.wpUsers.filter(user => user.roles.includes("administrator")).length
    }

    clearUserChanges(modalIdentifier) {
        if (confirm('This will clear all current un-committed changes. Would you like to continue?')) {
            this.userChanges = {}
            this.selectedUsers.forEach(user => {
                user.newRoles = [...user.roles]
                user.editingUserRole = false
            })
            $(`#${modalIdentifier}`).modal('hide')
        }
    }

    editRole(user) {
        user.editingUserRole = user.editingUserRole ? false : true
    }

    updateRoleList(user, evt) {
        const newRole = evt.target.value

        if (!user.newRoles.length) {
            user.newRoles = [...user.roles]
        }

        if (evt.target.checked) {
            // If the newRoles doesn't already contain the role then add it
            if (user.newRoles.indexOf(newRole) === -1) {
                user.newRoles.push(newRole)
            }
        } else {
            // if the list definitely contains the role being unchecked then remove it
            if (user.newRoles.indexOf(newRole) !== -1) {
                user.newRoles.splice(user.newRoles.indexOf(newRole), 1)
            }
        }

        const rolesToAdd = []
        const rolesToDelete = []

        user.roles.forEach(role => {
            if (user.newRoles.indexOf(role) === -1) {
                rolesToDelete.push(role)
            }
        })

        user.newRoles.forEach(role => {
            if (user.roles.indexOf(role) === -1) {
                rolesToAdd.push(role)
            }
        })

        const anyRoleLeft = [...user.roles, ...rolesToAdd]

        rolesToDelete.forEach(role => {
            if (anyRoleLeft.indexOf(role) !== -1) {
                anyRoleLeft.splice(anyRoleLeft.indexOf(role), 1)
            }
        })

        if (!anyRoleLeft.length) {
            ShowNotification.fail(
                'This will leave this user without a role.'
            )
        }

        if (rolesToAdd.length) {
            this.assignUserChanges(
                'role',
                'add',
                {
                    user: [user.ID],
                    capability: rolesToAdd
                }
            )
        } else {
            this.removeUserChanges('role', 'add', user)
        }

        if (rolesToDelete.length) {
            this.assignUserChanges(
                'role',
                'delete',
                {
                    user: [user.ID],
                    capability: rolesToDelete
                }
            )
        } else {
            this.removeUserChanges('role', 'delete', user)
        }
    }

    itemRoleList(roles) {
        if (!roles || !roles.length) {
            return 'None'
        }

        return roles.map(role => (role.charAt(0).toUpperCase() + role.slice(1))).join(', ')
    }

    cleanupUserChanges(parentAction, action) {
        // Clean up empty changes
        this.userChanges[parentAction][action] = this.userChanges[parentAction][action].map(change => {
            if (change.user.length) {
                return change
            }

            return false
        }).filter(Boolean)
    }

    assignUserChanges(parentAction, action, data) {
        if (this.userChanges.hasOwnProperty(parentAction)) {
            if (this.userChanges[parentAction][action]) {
                let hasAdded = false
                this.userChanges[parentAction][action].forEach(change => {
                    switch (parentAction) {
                        case 'role':
                        case 'capability':
                            // If we have a matching capability set
                            if (checkArraysAreEqual(change.capability.sort(), data.capability.sort())) {
                                // Check if the user is NOT already assigned to that set
                                // and add them to it
                                if (change.user.indexOf(data.user[0]) === -1) {
                                    change.user.push(data.user[0])
                                }

                                hasAdded = true
                            } else {
                                if (change.user.indexOf(data.user[0]) !== -1) {
                                    change.user.splice(change.user.indexOf(data.user[0]), 1)
                                }
                            }
                            break
                        default:
                            // This shouldn't happen silly billy.
                            break
                    }
                })

                // Clean up empty changes
                this.cleanupUserChanges(parentAction, action)

                // If the data didn't match then we append it finally
                if (!hasAdded) {
                    this.userChanges[parentAction][action].push(data)
                }
            } else {
                this.userChanges[parentAction][action] = [data]
            }
        } else {
            this.userChanges[parentAction] = {}
            this.userChanges[parentAction][action] = [data]
        }
    }

    removeUserChanges(parentAction, action, user) {
        if (!this.userChanges[parentAction] || !this.userChanges[parentAction][action]) return

        // Remove user id from associated list
        this.userChanges[parentAction][action].forEach(change => {
            if (change.user.indexOf(user.ID) !== -1) {
                change.user.splice(change.user.indexOf(user.ID), 1)
            }
        })

        // Clean up empty change sets
        this.cleanupUserChanges(parentAction, action)

        // If the child action set is empty then remove it
        if (!
            Object.keys(this.userChanges[parentAction][action]).length
        ) {
            delete this.userChanges[parentAction][action]
        }

        // If the remaining parent action set is empty then remove it
        if (!
            Object.keys(this.userChanges[parentAction]).length
        ) {
            delete this.userChanges[parentAction]
        }
    }

    updateUserCapabilities(capability, user) {
        user.capabilities = user.capabilities.map(cap => {
            if (cap.value === capability) {
                cap.toRemove = cap.toRemove ? false : true
            }

            return cap
        })

        const toRemove = user.capabilities.filter(cap => cap.toRemove)

        if (toRemove.length) {
            this.assignUserChanges(
                'capability',
                'delete',
                {
                    user: [user.ID],
                    capability: toRemove.map(cap => cap.value)
                }
            )
        } else {
            this.removeUserChanges('capability', 'delete', user)
        }
    }

    spawnManageUsersModal(id) {
        $(id).modal('show')
    }

    spawnCreateUsersModal(id) {
        $(id).modal('show')
    }

    commitActions(key) {
        this.committing[key] = true

        const actions = this[`wp${capitalised(key)}`].reduce((acc, item) => {
            const itemActions = Object.keys(item.actionChecks).filter(action => action !== 'disableOthers')

            if (!itemActions || !itemActions.length) {
                return acc
            }

            if (!acc[itemActions[0]] || !acc[itemActions[0]].length) {
                acc[itemActions[0]] = []
            }

            acc[itemActions[0]].push(item.name)

            return acc
        }, {})

        this.missionControl.handleGridActions(key, this.externalId, actions)
    }

    updateActions(key, item, checked) {
        if (checked) {
            item.actionChecks = {
                disableOthers: key,
                [key]: true
            }
        } else {
            item.actionChecks = {
                disableOthers: null
            }
        }
    }

    updateAll() {
        const virtualHost = this.externalId
        const actions = {}
        const types = []

        if (this.updateablePlugins && this.updateablePlugins.length) {
            types.push('plugins')
            actions['plugin'] = {}
            actions['plugin']['update'] = []
            this.updateablePlugins.forEach(plugin => {
                actions['plugin']['update'].push(plugin.name)
            })
        }

        if (this.updateableThemes && this.updateableThemes.length) {
            types.push('themes')
            actions['theme'] = {}
            actions['theme']['update'] = []
            this.updateableThemes.forEach(theme => {
                actions['theme']['update'].push(theme.name)
            })
        }

        this.missionControl.handleActions(types, virtualHost, actions, { forceBulk: true })
    }

    retryMalwareScan() {
        const job = this.missionControl.getJobToRetryByPackage(this, 'MalwareScanReport')

        this.instanceData.malwareReport.loaded = false
        this.instanceData.malwareReport.data = false

        this.missionControl.retryJob(job)
    }

    clearAllActions(identifier) {
        this.allSwitches = {}

        this[`wp${capitalised(identifier)}`] = this[`wp${capitalised(identifier)}`].map(item => {
            item.actionChecks = {}
            return item
        })
    }

    clearSelectedUsers() {
        this.wpUsers = this.wpUsers.map(item => {
            return {
                ...item,
                selected: false
            }
        })
    }

    switchAll(action, identifier, checked) {
        console.log(identifier)
        this.allSwitches[action] = checked
        this[`wp${capitalised(identifier)}`] = this[`wp${capitalised(identifier)}`].map(item => {
            switch (action) {
                case 'activate':
                    if (item.status === 'inactive') {
                        item.actionChecks.disableOthers = checked ? 'activate' : null
                        Object.assign(item.actionChecks, {
                            'activate': checked
                        })
                    }
                    break
                case 'deactivate':
                    if (item.status === 'active') {
                        item.actionChecks.disableOthers = checked ? 'deactivate' : null
                        Object.assign(item.actionChecks, {
                            'deactivate': checked
                        })
                    }
                    break
                case 'delete':
                    item.actionChecks.disableOthers = checked ? 'delete' : null
                    Object.assign(item.actionChecks, {
                        'delete': checked
                    })
                    break
                case 'uninstall':
                    item.actionChecks.disableOthers = checked ? 'uninstall' : null
                    Object.assign(item.actionChecks, {
                        'uninstall': checked
                    })
                    break
                case 'update':
                    if (item.update !== 'none') {
                        item.actionChecks.disableOthers = checked ? 'update' : null
                        Object.assign(item.actionChecks, {
                            'update': checked
                        })
                    }
                    break
                case 'verify':
                    if( !item.mustUse ){
                        item.actionChecks.disableOthers = checked ? 'verify' : null
                        Object.assign(item.actionChecks, {
                            'verify': checked
                        })
                    }
                    break
            }

            return item
        })
    }

    updateJobs(jobs) {
        const jobMap = this.missionControl.generateJobMap(jobs, { ...this.instanceData })

        let isLatest = false
        let current = null
        /**
         * @type {?boolean}
         */
        let installed = false
        let update = null
        let available = null

        if (jobMap['version'] && jobMap['version'] === 'failed') {
            installed = null
        }

        if (jobMap['version'] && jobMap['version'] !== 'failed') {
            const versionData = jobMap['version']
            let updateTo = false
            if (versionData.available && Array.isArray(versionData.available)) {
                updateTo = versionData.available.reduce((resultingVersion, version) => {
                    if (resultingVersion.type === 'minor') {
                        if (version.update_type === 'minor') {
                            resultingVersion.version = version.version > resultingVersion.version ? version.version : resultingVersion.version
                        }
                    }

                    if (resultingVersion.type === null || version.update_type === 'minor') {
                        resultingVersion.type = version.update_type
                        resultingVersion.version = version.version
                    }

                    return resultingVersion
                }, { type: null, version: null })
            }

            available = versionData.available
            isLatest = updateTo ? false : true
            current = versionData.installed
            installed = versionData.installed || null
            update = updateTo
        }

        const loadedCondition = (type) => {
            if (jobMap[type] === false) {
                return false
            }

            if (jobMap[type] === 'failed') {
                return 'failed'
            } else if (
                jobMap[type] &&
                (
                    type === 'malwareReport' && Object.keys(jobMap[type]).length ||
                    jobMap[type].length
                )
            ) {
                return true
            }

            return 'empty'
        }

        const itemsByType = (type) => {
            if (jobMap[type] && jobMap[type] !== 'failed') {
                return jobMap[type]
            }

            return []
        }

        Object.assign(this.instanceData, {
            isLatest: isLatest,
            current: current,
            update: update,
            available: available,
            /**
             * @type {?boolean|string}
             */
            installed,
            /**
             * @type {instanceObjectData}
             */
            malwareReport: {
                loaded: loadedCondition('malwareReport'),
                data: jobMap['malwareReport']
            },
            /**
             * @type {instanceObjectData}
             */
            scanReport: {
                loaded: loadedCondition('scanReport'),
                data: {}
            },
            /**
             * @type {instanceArrayData}
             */
            plugins: {
                loaded: loadedCondition('plugins'),
                items: itemsByType('plugins')
            },
            /**
             * @type {instanceArrayData}
             */
            themes: {
                loaded: loadedCondition('themes'),
                items: itemsByType('themes')
            },
            /**
             * @type {instanceArrayData}
             */
            users: {
                loaded: loadedCondition('users'),
                items: itemsByType('users')
            },
            /**
             * @type {instanceArrayData}
             */
            admins: {
                loaded: loadedCondition('admins'),
                items: jobMap['admins'] ?? []
            },
            /**
             * @type {instanceItemData}
             */
            stagingLink: {
                loaded: loadedCondition('staging'),
                content: jobMap['stagingLink'] || null
            }
        })
    }
}
